import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { drag } from 'd3-drag';
import { select, event } from 'd3-selection';
import throttle from 'lodash/throttle';

import { drawGraph } from './simulation/draw';
import { colors, nodeRadius } from './simulation/config';
import './ForceDirectedGraph.css';
import Simulation from './simulation';
import { nodeSelector, linkSelector } from './selectors/simulation';
import { highlightPerson, reselectPerson } from './effects/data';
import {
  selectedPersonInfluencerSelector,
  selectedPersonInfluencedSelector,
  selectedPersonIdSelector,
} from './selectors/data';
import { withRouter } from 'react-router-dom';
import { getPathFromURI } from './data/utils';

function ForceDirectedGraph({
  people,
  influence,
  selected,
  selectedInfluencers,
  selectedInfluenceds,
  highlighted,
  onHover,
  onClick,
  onReclick,
}) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const canvasRef = useRef(null);
  const [simulation] = useState(new Simulation());
  const [offset, setOffset] = useState(0);
  const [simulationNodes, setSimulationNodes] = useState([]);

  // update canvas size on resize
  useEffect(() => {
    const updateCanvasSize = throttle(() => {
      const {
        top,
        bottom,
        left,
        right,
      } = canvasRef.current.getBoundingClientRect();

      const height = bottom - top;
      const width = right - left;

      setHeight(height);
      setWidth(width);
    }, 100);

    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);

    return () => {
      window.removeEventListener('resize', updateCanvasSize);
    };
  }, []);

  // run the simulation
  useEffect(() => {
    const onTick = () => {
      setSimulationNodes(
        simulation.nodes.map(({ name, uri, x, y, radius }) => ({
          name,
          uri,
          x,
          y,
          radius,
        }))
      );
    };

    const initialNodes = people.map(node => ({
      ...node,
      radius: nodeRadius(node),
    }));
    const initialLinks = influence.map(link => ({ ...link }));

    simulation.start({
      nodes: initialNodes,
      links: initialLinks,
      height: 0,
      onTick,
    });

    return () => simulation.stop();
  }, [simulation, people, influence]);

  // resize and refresh the simulation when the height changes
  useEffect(() => {
    simulation.resize({ height });
  }, [height, simulation]);

  // draw graph
  useEffect(() => {
    // get canvas context
    const context = canvasRef.current.getContext('2d');

    const selections = [
      {
        name: 'influenceds',
        uris: selectedInfluenceds,
        color: colors.influencedNode,
      },
      {
        name: 'influencers',
        uris: selectedInfluencers,
        color: colors.influencedByNode,
      },
      {
        name: 'highlighted',
        uris: highlighted ? [highlighted] : [],
        color: colors.highlightedNode,
        labels: true,
      },
      {
        name: 'selected',
        uris: selected ? [selected] : [],
        color: colors.selectedNode,
        labels: true,
      },
    ];

    // draw the graph
    const nodesToDraw = Object.fromEntries(
      simulationNodes.map(node => [
        node.uri,
        { ...node, x: node.x + offset + width / 2 },
      ])
    );

    drawGraph(nodesToDraw, { context, height, width, selections });
  }, [
    simulationNodes,
    width,
    height,
    offset,
    selected,
    selectedInfluencers,
    selectedInfluenceds,
    highlighted,
  ]);

  useEffect(() => {
    // move the image by dragging
    const canvas = canvasRef.current;
    select(canvas).call(
      drag()
        .clickDistance(2)
        .on('start', () => {
          setOffset(offset => offset + event.dx);
        })
        .on('drag', () => {
          setOffset(offset => offset + event.dx);
        })
        .on('end', () => {
          setOffset(offset => offset + event.dx);
        })
    );

    return () => {
      select(canvas).on('.start', null);
      select(canvas).on('.drag', null);
      select(canvas).on('.end', null);
    };
  }, [canvasRef]);

  const getCanvasCoordinate = ({ clientX, clientY }) => {
    // get the position of click relative to canvas
    const { top, left } = canvasRef.current.getBoundingClientRect();
    const x = clientX - left;
    const y = clientY - top;
    return { x, y };
  };

  const findNode = ({ x, y }) => {
    const node = simulation.selectNode({ x: x - offset - width / 2, y });
    return node?.uri ?? '';
  };

  const handleClick = event => {
    const { clientX, clientY } = event;
    const { x, y } = getCanvasCoordinate({ clientX, clientY });
    const node = findNode({ x, y });
    if (node === selected) {
      onReclick(node);
    } else {
      onClick(node);
    }
  };

  const handleMouseMove = event => {
    const { clientX, clientY } = event;
    const { x, y } = getCanvasCoordinate({ clientX, clientY });
    const node = findNode({ x, y });
    if (node !== highlighted) onHover(node);
  };

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      onClick={handleClick}
      onMouseMove={handleMouseMove}
      onMouseOut={() => onHover()}
    />
  );
}

export default withRouter(
  connect(
    (state, { location }) => ({
      people: nodeSelector(state),
      influence: linkSelector(state),
      selected: selectedPersonIdSelector(state, { location }),
      selectedInfluencers: selectedPersonInfluencerSelector(state, {
        location,
      }),
      selectedInfluenceds: selectedPersonInfluencedSelector(state, {
        location,
      }),
      highlighted: state.data.highlightedPersonId,
    }),
    (dispatch, { history }) => ({
      onClick: uri => history.push(getPathFromURI(uri)),
      onReclick: uri => dispatch(reselectPerson(uri)),
      onHover: uri => dispatch(highlightPerson(uri)),
    })
  )(ForceDirectedGraph)
);
