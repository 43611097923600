import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'search',
  initialState: { query: '' },
  reducers: {
    changeQuery(state, action) {
      state.query = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = slice;
// Extract and export each action creator by name
export const { changeQuery } = actions;
// Export the reducer, either as a default or named export
export default reducer;
