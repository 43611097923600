import React from 'react';
import { Button, Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { openPersonInfoWindow, closePersonInfoWindow } from './slices/data';
import { selectedPersonSelector } from './selectors/data';

function InfoControl({ disabled, open, children, onOpen, onClose }) {
  return (
    <div id="right-info-bar">
      <div>
        {!disabled && (
          <div>
            {!open ? (
              <Button color="link" id="show-info" onClick={onOpen}>
                SHOW INFO
              </Button>
            ) : (
              <Button color="link" id="hide-info" onClick={onClose}>
                HIDE INFO
              </Button>
            )}
          </div>
        )}
        <Collapse id="collapse" isOpen={open}>
          {children}
        </Collapse>
      </div>
    </div>
  );
}

export default withRouter(
  connect(
    (state, { location }) => ({
      disabled: !selectedPersonSelector(state, { location }),
      open: state.data.personInfoWindowOpen,
    }),
    {
      onOpen: openPersonInfoWindow,
      onClose: closePersonInfoWindow,
    }
  )(InfoControl)
);
