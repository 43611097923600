import React from 'react';
import styled from 'styled-components';
import { getIdentifierFromURI } from './data/utils';

const DBpediaToWikipediaLink = url => {
  if (!url) return '';

  return `https://en.wikipedia.org/wiki/${getIdentifierFromURI(url)}`;
};

const LinkSection = styled.section`
  display: flex;
  justify-content: space-around;
`;

export default function PersonLinks({ person }) {
  if (!person) return null;

  const { uri } = person;

  return (
    <LinkSection>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={DBpediaToWikipediaLink(uri)}
      >
        <img
          src="/pictures/WikipediaIcon.svg"
          width="30px"
          alt="wikipedia icon"
        />
        {' Wikipedia'}
      </a>
      <a target="_blank" rel="noopener noreferrer" href={uri}>
        <img src="/pictures/DBpediaIcon.png" width="30px" alt="DBpedia icon" />
        {' DBpedia'}
      </a>
    </LinkSection>
  );
}
