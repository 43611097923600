import React from 'react';
import styled from 'styled-components';

const Description = styled.section`
  text-align: justify;
`;

export default function PersonDescription({ description }) {
  if (!description) return null;

  return (
    <section>
      <h3>Description:</h3>
      <Description>{description}</Description>
    </section>
  );
}
