import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import InfluencedList from './InfluencedList';
import PersonDescription from './PersonDescription';
import PersonLinks from './PersonLinks';
import PersonPicture from './PersonPicture';
import {
  selectedPersonSelector,
  selectedInfluencersSelector,
  selectedInfluencedsSelector,
} from './selectors/data';

const PersonInfoContainer = styled.div`
  position: relative;
  background: white;
  height: 100vh;
  overflow: auto;

  // font-family: 'Slabo 27px', serif;
  font-family: 'Noto Serif SC', serif;

  > * {
    margin: 20px 30px;
  }
`;

const PersonName = styled.h2`
  text-align: center;
  margin: initial;
  margin-bottom: 0.5rem;
`;

function PersonInfo({ person, influencers, influenceds }) {
  return (
    <PersonInfoContainer>
      <PersonName>{person?.name ?? 'Loading..'}</PersonName>
      <PersonPicture picture={person?.picture} />
      <PersonLinks person={person} />
      <InfluencedList title="Influenced" people={influenceds} />
      <InfluencedList title="Influenced by" people={influencers} />
      <PersonDescription description={person?.abstract} />
    </PersonInfoContainer>
  );
}

export default withRouter(
  connect((state, { location }) => ({
    person: selectedPersonSelector(state, { location }),
    influencers: selectedInfluencersSelector(state, { location }),
    influenceds: selectedInfluencedsSelector(state, { location }),
  }))(PersonInfo)
);
