import { forceSimulation, forceCollide, forceY, forceX } from 'd3-force';
import forceLinkHorizontal from './forceLinkHorizontal';
import forceManyBodyHorizontal from './forceManyBodyHorizontal';
import { getScaleTimeAgo, lifeToY } from './utils';

export default class Simulation {
  nodes = [];
  links = [];

  simulation = forceSimulation()
    .alphaDecay(0.02)
    .force(
      'link',
      forceLinkHorizontal().id(node => node.uri)
    )
    .force('charge', forceManyBodyHorizontal().strength(-50))
    .force(
      'collide',
      forceCollide(({ radius }) => radius + 2)
    )
    .stop();

  start = ({ nodes, links, height, onTick }) => {
    this.nodes = nodes;
    this.links = links;

    this.resize({ height });

    this.simulation.nodes(this.nodes);
    this.simulation.force('link').links(this.links);

    this.simulation.force('x', forceX(0)).force(
      'y',
      forceY()
        .y(node => node.Y)
        .strength(node => (node.Y === null ? 0 : 4))
    );

    this.simulation.on('tick', () => onTick({ nodes: this.nodes }));

    this.resize({ height });
  };

  countY = () => {
    this.nodes.forEach(node => {
      node.Y = lifeToY(node, this.scale);
    });
  };

  selectNode = ({ x, y }) => this.simulation.find(x, y, 32);

  stop = () => this.simulation.stop();

  resize = ({ height }) => {
    const oldHeight = this.height;

    if (oldHeight !== height) {
      this.simulation.stop();
      this.height = height;
      this.scale = getScaleTimeAgo({ height: this.height });
      this.countY();
      this.simulation.force(
        'y',
        forceY()
          .y(node => node.Y)
          .strength(node => (node.Y === null ? 0 : 4))
      );
      this.countY();
      this.simulation.alpha(Math.max(this.simulation.alpha(), 0.2));
      this.simulation.restart();
    }
  };
}
