import { scaleLog } from 'd3-scale';
import { getLifeMidpoint } from '../data/utils';
const NOW = Date.now();

/**
 * Count milliseconds that passed since a point in history
 * @param {Date|Number} time - Date or timestamp in history
 * @param {Date|Number} now=Date.now() - a Date or timestamp which is now
 * @returns {Number} - milliseconds from now till historical date
 */
function millisecondsAgo(time, now = NOW) {
  if (time instanceof Date) time = time.getTime();
  if (now instanceof Date) now = now.getTime();

  return now - time;
}

export function getScaleTimeAgo({
  height,
  oldTime = new Date(-1000, 0, 1),
  newTime = new Date(2000, 0, 1),
}) {
  return scaleLog()
    .nice()
    .domain([millisecondsAgo(oldTime), millisecondsAgo(newTime)])
    .range([10, height - 10]);
}

export function timeToY(time, scale) {
  return scale(millisecondsAgo(time));
}

export function lifeToY(person, scale) {
  return timeToY(getLifeMidpoint(person), scale);
}
