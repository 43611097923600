import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  gender: undefined,
  influencedCountMin: 6, // maybe Max?
  influencedByCountMin: 0,
  influencedByCountMax: undefined,
};

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {},
});

const { reducer } = slice;
export default reducer;
