import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  influenced: {},
  influencedBy: {},
  persons: {},
  selectedPersonData: undefined,
  highlightedPersonId: undefined,
  personInfoWindowOpen: false, // TODO do we need a separate reducer for this info?
};

const slice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    savePersons(state, action) {
      state.persons = {};
      for (const person of action.payload) {
        state.persons[person.uri] = person;
      }
    },
    savePairs(state, action) {
      const pairs = action.payload;
      state.influenced = {};
      state.influencedBy = {};

      for (const p of pairs) {
        if (!state.influenced[p[0]]) state.influenced[p[0]] = [];
        if (!state.influencedBy[p[1]]) state.influencedBy[p[1]] = [];

        state.influenced[p[0]] = [...new Set(state.influenced[p[0]]).add(p[1])];
        state.influencedBy[p[1]] = [
          ...new Set(state.influencedBy[p[1]]).add(p[0]),
        ];
      }
    },
    saveSelectedPersonData(state, action) {
      const { picture, abstract, name } = action.payload;

      state.selectedPersonData = {
        picture,
        abstract,
        name,
      };
    },
    clearSelectedPersonData(state) {
      state.selectedPersonData = initialState.selectedPersonData;
    },
    highlightPerson(state, action) {
      state.highlightedPersonId = action.payload;
    },
    unhighlightPerson(state) {
      state.highlightedPersonId = undefined;
    },
    openPersonInfoWindow(state) {
      state.personInfoWindowOpen = true;
    },
    closePersonInfoWindow(state) {
      state.personInfoWindowOpen = false;
    },
  },
});

const { actions, reducer } = slice;
export const {
  savePersons,
  savePairs,
  saveSelectedPersonData,
  clearSelectedPersonData,
  highlightPerson,
  unhighlightPerson,
  openPersonInfoWindow,
  closePersonInfoWindow,
} = actions;
export default reducer;
