import { fetchFile } from './utils';

function formatPeople(rawPeople) {
  return rawPeople.results.bindings.map(
    ({ person, gender, name, birth, death }) => ({
      uri: person.value,
      name: name && name.value,
      gender: gender && gender.value,
      birth: birth && parseDate(birth.value),
      death: death && parseDate(death.value),
    })
  );
}

export default async function fetchPeople() {
  const [people0, people1] = await Promise.all([
    fetchFile('/data/people/people0.json'),
    fetchFile('/data/people/people1.json'),
  ]);

  return [...formatPeople(people0), ...formatPeople(people1)];
}

function parseDate(dateString) {
  const regex = /^(-?\d{1,4})-(\d{1,2})-(\d{1,2})$/;

  const output = regex.exec(dateString);

  if (!output) return null;

  const [, year, month, day] = output;

  const date = new Date();
  date.setFullYear(year); // setYear maps 0-99 to 1900 - 1999
  date.setMonth(month - 1); // month index starts at 0
  date.setDate(day);

  return date.getTime();
}
