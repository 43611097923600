import { createSelector } from '@reduxjs/toolkit';
import { peopleSelector, influencedSelector } from './data';

const minCountSelector = state => state.filters.influencedCountMin;

const personsWithInfluencedCountSelector = createSelector(
  peopleSelector,
  influencedSelector,
  (persons, influenced) => {
    const entries = Object.entries(persons);
    const entriesWithCount = entries.map(([uri, node]) => [
      uri,
      {
        ...node,
        influencedCount: influenced?.[uri]?.length || 0,
      },
    ]);
    return Object.fromEntries(entriesWithCount);
  }
);

const displayPersonsSelector = createSelector(
  personsWithInfluencedCountSelector,
  minCountSelector,
  (persons, minCount) => {
    const entries = Object.entries(persons);
    const filteredEntries = entries.filter(
      ([, { influencedCount }]) => influencedCount >= minCount
    );
    return Object.fromEntries(filteredEntries);
  }
);

export const nodeSelector = createSelector(displayPersonsSelector, persons =>
  Object.entries(persons).map(([, nodes]) => nodes)
);

export const linkSelector = createSelector(
  displayPersonsSelector,
  influencedSelector,
  (nodes, linkDict) =>
    Object.keys(linkDict).reduce((links, sourceUri) => {
      // get set of targetUri
      const targetUriSet = linkDict[sourceUri];

      // if link finds its source and target in nodes, filter the link
      if (nodes[sourceUri]) {
        targetUriSet.forEach(targetUri => {
          if (nodes[targetUri]) {
            links.push({ source: sourceUri, target: targetUri });
          }
        });
      }
      return links;
    }, [])
);
