import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Collapse } from 'reactstrap';
import styled from 'styled-components';
import LinkButton from './LinkButton';
import { highlightPerson, unhighlightPerson } from './slices/data';

const HeaderContainer = styled.header`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Header = styled.h3`
  white-space: nowrap;
`;

const ExpandButton = styled(Button)`
  padding-right: 0;
  width: 70px;
`;

function InfluencedList({ title, people, onEnterPerson, onLeavePerson }) {
  const [collapsed, setCollapsed] = useState(true);

  const numberOfShowedLinks = 4;
  const manyLinks = people.length > numberOfShowedLinks;

  if (people.length === 0) return null;

  return (
    <section>
      {/* title and number of influenced/influenced by */}
      <HeaderContainer>
        <Header>
          {title}: {people.length}
        </Header>
        {/* more/less button */}
        {manyLinks && (
          <ExpandButton
            color="link"
            onClick={() => setCollapsed(collapsed => !collapsed)}
          >
            {collapsed ? <span>more&hellip;</span> : 'less'}
          </ExpandButton>
        )}
      </HeaderContainer>
      {/* list of links shown at the beginning */}
      {people.slice(0, numberOfShowedLinks).map(person => (
        <LinkButton
          person={person}
          key={person.uri}
          onEnter={() => onEnterPerson(person.uri)}
          onLeave={() => onLeavePerson()}
        />
      ))}
      {/* list of links shown in Collapse */}
      {manyLinks && (
        <Collapse isOpen={!collapsed}>
          {people.slice(numberOfShowedLinks).map(person => (
            <LinkButton
              person={person}
              key={person.uri}
              onEnter={() => onEnterPerson(person.uri)}
              onLeave={() => onLeavePerson()}
            />
          ))}
        </Collapse>
      )}
    </section>
  );
}

export default connect(null, {
  onEnterPerson: highlightPerson,
  onLeavePerson: unhighlightPerson,
})(InfluencedList);
