import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { titleSelector } from './selectors/data';

function Title({ title }) {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
}

export default withRouter(
  connect((state, { location }) => ({
    title: titleSelector(state, { location }),
  }))(Title)
);
