import React from 'react';
import { connect } from 'react-redux';
import { changeQuery } from './slices/search';
import { highlightPerson, unhighlightPerson } from './slices/data';
import { searchQuerySelector, searchResultSelector } from './selectors/search';
import { Link } from 'react-router-dom';
import { getPathFromURI } from './data/utils';

function Search({
  query,
  people,
  onChangeQuery,
  onEnterPerson,
  onLeavePerson,
}) {
  const handleInputChange = event => {
    onChangeQuery(event.target.value);
  };

  const handleClickPerson = () => {
    onChangeQuery('');
  };

  return (
    <>
      <input onChange={handleInputChange} value={query} placeholder="Search" />
      {people.length > 0 && (
        <ul>
          {people.map(({ name, uri }) => (
            <li key={uri}>
              <Link
                to={getPathFromURI(uri)}
                onClick={() => handleClickPerson(uri)}
                onMouseOver={() => onEnterPerson(uri)}
                onMouseOut={() => onLeavePerson()}
              >
                {name || uri}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}

export default connect(
  state => ({
    query: searchQuerySelector(state),
    people: searchResultSelector(state),
  }),
  {
    onChangeQuery: changeQuery,
    onEnterPerson: highlightPerson,
    onLeavePerson: unhighlightPerson,
  }
)(Search);
