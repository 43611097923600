import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import './index.css';

import * as serviceWorker from './serviceWorker';
import store from './store';
import { savePersons, savePairs } from './slices/data';

import fetchPeople from './data/fetchPeople';
import fetchLinks from './data/fetchLinks';

/**
 * Load data
 */
(async () => {
  const persons = await fetchPeople();
  const pairs = await fetchLinks();

  // get data from file
  store.dispatch(savePersons(persons));
  store.dispatch(savePairs(pairs));
})();

const AppRedux = (
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);

ReactDOM.render(AppRedux, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
