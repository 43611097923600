import { configureStore } from '@reduxjs/toolkit';
import data from './slices/data';
import filters from './slices/filters';
import search from './slices/search';
import thunk from 'redux-thunk';

export default configureStore({
  reducer: {
    data,
    filters,
    search,
  },
  devTools: false,
  middleware: [thunk],
});
