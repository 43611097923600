import { getNameFromURI, getURIFromPath } from '../data/utils';
import { createSelector } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

export const selectedPersonIdSelector = (state, props) =>
  getURIFromPath(props.location.pathname);
const selectedPersonDataSelector = state => state.data.selectedPersonData;
const influencerSelector = state => state.data.influencedBy;
export const influencedSelector = state => state.data.influenced;
export const peopleSelector = state => state.data.persons;

/**
 * Provided store, find out whether initial data is loading or not
 * @param {State}
 * @returns {Boolean}
 */
export const isDataLoadingSelector = createSelector(
  peopleSelector,
  influencedSelector,
  influencerSelector,
  (people, influenced, influencedBy) =>
    isEmpty(people) || isEmpty(influenced) || isEmpty(influencedBy)
);

export const titleSelector = createSelector(
  selectedPersonIdSelector,
  selectedPersonId => {
    const name = selectedPersonId
      ? getNameFromURI(selectedPersonId)
      : 'Influenced';
    return `${name} · Who influenced who?`;
  }
);

export const selectedPersonInfluencerSelector = createSelector(
  selectedPersonIdSelector,
  influencerSelector,
  (personId, influencedBy) => influencedBy[personId] || []
);

export const selectedPersonInfluencedSelector = createSelector(
  selectedPersonIdSelector,
  influencedSelector,
  (personId, influenced) => influenced[personId] || []
);

export const selectedPersonSelector = createSelector(
  selectedPersonIdSelector,
  selectedPersonDataSelector,
  (uri, person) => (uri && person ? { ...person, uri } : null)
);

const selectPeopleByIds = (ids, people, influencers, influenceds) =>
  ids
    .map(id => {
      const person = people[id];
      const influencerCount = influencers[id]?.length ?? 0;
      const influencedCount = influenceds[id]?.length ?? 0;
      return { ...person, influencerCount, influencedCount };
    })
    .sort((a, b) => b.influencedCount - a.influencedCount);

// select influencers of the selected person, including their influence counts
export const selectedInfluencersSelector = createSelector(
  selectedPersonInfluencerSelector,
  peopleSelector,
  influencerSelector,
  influencedSelector,
  selectPeopleByIds
);

// select influenced people by the selected person, including their influence counts
export const selectedInfluencedsSelector = createSelector(
  selectedPersonInfluencedSelector,
  peopleSelector,
  influencerSelector,
  influencedSelector,
  selectPeopleByIds
);
