import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './App.scss';
import Graph from './Graph';
import InfoControl from './InfoControl';
import PersonInfo from './PersonInfo';
import Search from './Search';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckSquare,
  faCoffee,
  faChevronRight,
  faTimes,
  faAngleDoubleDown,
} from '@fortawesome/free-solid-svg-icons';
import Title from './Title';
import { selectPerson } from './effects/data';
import { getURIFromPath } from './data/utils';

// font awesome icons are added to library here and later used
// in the whole app without need for import
library.add(
  faChevronRight,
  faCheckSquare,
  faCoffee,
  faTimes,
  faAngleDoubleDown
);

const App = ({ location, onLocationChange }) => {
  // load the person when location changes
  useEffect(() => {
    const uri =
      location.pathname.length > 1 ? getURIFromPath(location.pathname) : '';
    onLocationChange(uri);
  }, [location, onLocationChange]);

  return (
    <>
      <Title />
      <div>
        <header id="main-header">
          <h1>Influenced</h1>{' '}
          <nav>
            <Search />
          </nav>
        </header>
        <div id="main-graph">
          <Graph />
        </div>
        <InfoControl>
          <PersonInfo />
        </InfoControl>
      </div>
    </>
  );
};

export default withRouter(
  connect(null, {
    onLocationChange: selectPerson,
  })(App)
);
