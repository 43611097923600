import * as actions from '../slices/data';
import {
  clearSelectedPersonData,
  saveSelectedPersonData,
  openPersonInfoWindow,
  closePersonInfoWindow,
} from '../slices/data';
import { getUserData } from '../data/utils';

export const selectPerson = id => dispatch => {
  if (id) {
    dispatch(loadPerson(id));
  } else {
    dispatch(clearSelectedPerson());
  }
};

export const reselectPerson = id => dispatch => {
  if (id) {
    dispatch(openPersonInfoWindow());
  } else {
    dispatch(closePersonInfoWindow());
  }
};

export const highlightPerson = id => dispatch => {
  if (id) {
    dispatch(actions.highlightPerson(id));
  } else {
    dispatch(actions.unhighlightPerson());
  }
};

export const loadPerson = id => async dispatch => {
  dispatch(saveSelectedPersonData({ uri: id }));
  dispatch(openPersonInfoWindow());
  const userData = await getUserData(id, 'en');
  dispatch(saveSelectedPersonData(userData));
};

export const clearSelectedPerson = () => dispatch => {
  dispatch(clearSelectedPersonData());
  dispatch(closePersonInfoWindow());
};
