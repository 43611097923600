import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { getPathFromURI } from './data/utils';

export default function LinkButton({ person, onEnter, onLeave }) {
  return (
    <Button
      tag={Link}
      to={getPathFromURI(person.uri)}
      onMouseOver={onEnter}
      onMouseOut={onLeave}
      onFocus={onEnter}
      onBlur={onLeave}
      className="link-button"
      outline
      color="link"
      size="sm"
    >
      {/* influenced by */}
      {person.influencerCount} <FontAwesomeIcon icon="chevron-right" />{' '}
      {person.name}
      {/* influenced */} <FontAwesomeIcon icon="chevron-right" />{' '}
      {person.influencedCount}
    </Button>
  );
}
