import React from 'react';
import { connect } from 'react-redux';
import ForceDirectedGraph from './ForceDirectedGraph';
import { isDataLoadingSelector } from './selectors/data';

function Graph({ isDataLoading }) {
  if (isDataLoading) return <div>Loading...</div>;

  return <ForceDirectedGraph />;
}

export default connect(state => ({
  isDataLoading: isDataLoadingSelector(state),
}))(Graph);
