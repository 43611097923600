import pick from 'lodash/pick';
import { getScaleTimeAgo, timeToY } from './utils';
import { colors } from './config';

/**
 * Draw a graph on canvas
 */
export function drawGraph(nodes, { context, height, width, selections = [] }) {
  // wipe out previous drawings
  context.clearRect(0, 0, width, height);

  const scale = getScaleTimeAgo({ height });

  const grid10 = yearsToGrid({ min: 1900, max: 2001, gap: 10, scale });
  const grid100 = yearsToGrid({ min: -1000, max: 1900, gap: 100, scale });

  drawGridLines({ grid: grid10, context, width, color: colors.yearLines10 });
  drawGridLines({ grid: grid100, context, width, color: colors.yearLines100 });

  // draw nodes
  // all nodes
  const exclude = selections.reduce((exclude, { uris }) => {
    if (!uris) return exclude;
    return [...exclude, ...uris];
  }, []);

  // draw all the unselected nodes
  drawNodes({ nodes, context, exclude });

  // draw nodes in selections
  selections.forEach(({ uris, color }) => {
    const nodesToDraw = pick(nodes, uris);
    drawNodes({ nodes: nodesToDraw, color, context });
  });

  // draw labels when specified
  selections.forEach(({ uris, labels }) => {
    if (!labels) return;
    const nodesToDraw = pick(nodes, uris);
    drawNodeLabels({ nodes: nodesToDraw, context });
  });

  context.fillStyle = 'rgba(255, 255, 255, 0.7)';
  context.fillRect(0, 0, 40, height);
  drawGridLabels({ context, grid: grid10 });
  drawGridLabels({ context, grid: grid100 });
}

function drawNodes({
  nodes,
  color = colors.defaultNode,
  context,
  exclude = [],
}) {
  context.fillStyle = color;
  context.beginPath();

  for (const uri in nodes) {
    const node = nodes[uri];
    if (!exclude.includes(uri)) {
      pathNode(node, context);
    }
  }
  context.fill();
}

function drawNodeLabels({ nodes, context }) {
  const fontSize = 20;
  context.font = `${fontSize}px Arial`;
  context.textAlign = 'left';
  context.fillStyle = 'black';

  for (const uri in nodes) {
    const { x, y, name, radius } = nodes[uri];
    if (name) {
      context.fillText(name, x + radius + 1, y + fontSize / 2);
    }
  }
}

function pathNode({ x, y, radius }, context) {
  context.moveTo(x, y);
  context.arc(x, y, radius, 0, 2 * Math.PI);
}

function yearsToGrid({ min, max, gap, scale, fontSize = 12 }) {
  const grid = [];

  for (let year = min; year < max; year += gap) {
    const highlight = year % (5 * gap) === 0;
    const y = timeToY(new Date().setFullYear(year), scale);

    const isEnoughSpaceForLabel =
      grid.length === 0 || y - grid[grid.length - 1].y > fontSize;

    const label = isEnoughSpaceForLabel || highlight ? year : '';

    grid.push({ label, y, highlight });
  }

  return grid;
}

function drawGridLines({ context, width, grid, color }) {
  context.strokeStyle = color;

  grid.forEach(({ y, highlight }) => {
    context.beginPath();
    context.lineWidth = highlight ? 1 : 0.5; // TODO move it outside to some config
    context.moveTo(40, y);
    context.lineTo(width, y);
    context.stroke();
  });
}

function drawGridLabels({ context, grid }) {
  const fontSize = 12;
  context.font = `${fontSize}px Arial`;
  context.textAlign = 'right';
  context.fillStyle = 'black';

  grid.forEach(({ y, label }) => {
    context.fillText(label, 38, y + fontSize / 2); // text, x, y
  });
}
