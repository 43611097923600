import { createSelector } from '@reduxjs/toolkit';
import latinMap from './latinMap';
import { peopleSelector, influencedSelector } from './data';

const SEARCH_RESULT_LIMIT = 10;

export const searchQuerySelector = state => state.search.query;

export const searchResultSelector = createSelector(
  searchQuerySelector,
  peopleSelector,
  influencedSelector,
  (query, people, influenced) => {
    if (!people || !query) return [];

    return Object.keys(people)
      .filter(uri => isUriQueryMatched(uri, query))
      .sort(
        (a, b) =>
          (influenced?.[b]?.length || 0) - (influenced?.[a]?.length || 0)
      )
      .slice(0, SEARCH_RESULT_LIMIT)
      .map(uri => people[uri]);
  }
);

/**
 * Given dbpedia resource uri and query string, does the resource name match a query string?
 * @param {string} uri - http://dbpedia.org/resource/{Resource_Name}
 * @param {string} query - a query string
 * @returns {boolean}
 */
function isUriQueryMatched(uri, query) {
  try {
    // build a regular expression from the string without diacritics
    const regex = new RegExp(removeDiacriticsExtended(query), 'i');
    // get the resource name
    const name = uri.slice(28).split('_').join(' ');
    // test the name against the regex
    return regex.test(removeDiacriticsExtended(name));
  } catch {
    // sometimes the `new Regexp()` fails, i.e. when query='\'
    return false;
  }
}

/**
 * Remove diacritics from a string
 * @param {string} text
 * @returns {string} - text without diacritics
 */
// eslint-disable-next-line no-unused-vars
function removeDiacritics(text) {
  // https://stackoverflow.com/a/37511463
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

/**
 * Remove diacritics from a string. It works with a defined map of characters to unicode.
 * It replaces also characters which the other version doesn't.
 * @param {string} text
 * @returns {string} - text without diacritics
 */
function removeDiacriticsExtended(text) {
  // https://stackoverflow.com/a/9667752
  return text.replace(/[^A-Za-z0-9]/g, a => latinMap[a] || a);
}
