// there is no human alive who was born before 1900
const ALIVE = new Date('1900-01-01').getTime();

export async function fetchFile(url) {
  return (await fetch(url)).json();
}

export async function getUserData(url, lang = 'en') {
  const id = '<' + url + '>';
  const qs = {
    query: `PREFIX p: ${id}
    SELECT ?abstract, ?name, ?thumbnail
    WHERE {
      OPTIONAL { p: dbo:thumbnail | dbp:thumbnail ?thumbnail. }
      OPTIONAL { p: dbo:name | dbp:name | foaf:name ?name. }
      OPTIONAL {
        p: dbo:abstract | dbp:abstract ?abstract.
        FILTER (lang(?abstract) = '${lang}')
      }
    }`,
    format: 'application/sparql-results+json',
    timeout: 5000,
  };

  const uri = 'https://dbpedia.org/sparql';

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/sparql-results+json',
  };

  const qst =
    '?' +
    Object.keys(qs)
      .map(key => `${key}=${encodeURIComponent(qs[key])}`)
      .join('&');

  let results = await fetch(uri + qst, {
    headers,
    method: 'GET',
    mode: 'cors',
  });

  results = await results.json();
  let picture, abstract, name;
  if (results.results.bindings[0]) {
    ({ thumbnail: picture, abstract, name } = results.results.bindings[0]);
  }
  picture = picture?.value ?? 'no photo';
  abstract = abstract?.value ?? '';
  name = name?.value ?? '';
  return { picture, abstract, name };
}

export function getLifeMidpoint({ birth, death }) {
  // when birth and death are defined, return average
  if (birth !== undefined && death !== undefined) return (death + birth) / 2;
  if (birth) {
    // when birth is newer than 1900, return average of date.now and birth
    if (birth >= ALIVE) return (Date.now() + birth) / 2;
    // when birth is older than 1900, return birth
    return birth;
  }
  // when death, return death
  if (death) return death;

  return null;
}

/**
 * Transform URI and Identifier, Path or Name between each other
 */
export function getIdentifierFromURI(uri) {
  return uri.slice(uri.lastIndexOf('/') + 1);
}

export function getPathFromURI(uri) {
  return `/${getIdentifierFromURI(uri)}`;
}

export function getNameFromURI(uri) {
  return getIdentifierFromURI(uri).split('_').join(' ');
}

export function getURIFromPath(path) {
  return path.length > 1 ? `http://dbpedia.org/resource${path}` : '';
}
