import { fetchFile } from './utils';

function formatLinks(rawLinks) {
  return rawLinks.results.bindings.map(({ influencer, influenced }) => [
    influencer.value,
    influenced.value,
  ]);
}

export default async function fetchLinks() {
  const [links0, links1, links2] = await Promise.all([
    fetchFile('/data/links/links0.json'),
    fetchFile('/data/links/links1.json'),
    fetchFile('/data/links/links2.json'),
  ]);

  return [
    ...formatLinks(links0),
    ...formatLinks(links1),
    ...formatLinks(links2),
  ];
}
