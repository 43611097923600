/* eslint no-unused-vars: 0 */

// new style
const c1 = '#63a91f,#c6dc93,#b0d3bf,#589d62,#1a512e '.split(','); // http://colorpalettes.net/color-palette-3836/
const o1 = [2, 4, 0, 3, 1];
const c2 = '#ffd901,#ffbeae,#ffade2,#ff5682,#f42439'.split(','); //http://colorpalettes.net/color-palette-3819/
const o2 = [0, 2, 4, 3, 1];
const c3 = '#7d0a0f,#e66251,#fdb5a5,#fedaa7,#f39e38'.split(','); // http://colorpalettes.net/color-palette-3482/
const o3 = [3, 0, 4, 1, 2];
const c4 = '#02440c,#789400,#e4d565,#f3f5e7,#40becd'.split(','); // http://colorpalettes.net/color-palette-3805-2/
const o4 = [1, 2, 4, 0, 3];
const c5 = '#4d0001,#a20417,#ed4255,#007144,#004019'.split(','); // http://colorpalettes.net/color-palette-3805/
const o5 = [3, 1, 2, 0, 4];
const c6 = '#4d754d,#c7d690,#d4afab,#aa5864,#4a0e1c'.split(','); // http://colorpalettes.net/color-palette-2592/ ***
const o6 = [2, 1, 3, 0, 4];
const c7 = '#26205b,#3257af,#c8abd5,#f9c5d8,#b83e74'.split(','); // http://colorpalettes.net/color-palette-3770/
const o7 = [0, 4, 1, 2, 3];
const c8 = '#ae1903,#ff5600,#eab8a8,#9cb805,#89b5ab'.split(','); // http://colorpalettes.net/color-palette-3740/
const o8 = [3, 4, 1, 2, 0];
const o82 = [4, 2, 1, 3, 0];
const o83 = [3, 2, 1, 4, 0];
const c9 = '#f1e94d,#91ab6d,#e8e2cf,#65b2b8,#294653 '.split(','); // http://colorpalettes.net/color-palette-3703/
const c92 = '#ff7b7b,#91ab6d,#e8e2cf,#65b2b8,#ffbaba'.split(','); // http://colorpalettes.net/color-palette-3703/

const o9 = [2, 0, 1, 3, 4];
const c10 = '#a4dbe4,#fa990e,#fecc47,#5b8d27,#114b0b'.split(','); // http://colorpalettes.net/color-palette-3702/
const o10 = [2, 3, 1, 0, 4];
const c11 = '#264301,#5c8a03,#82b21e,#d6f58e,#d3e3b6'.split(','); // http://colorpalettes.net/color-palette-292/
const o11 = [4, 0, 1, 2, 3];
const c12 = '#31625b,#fcd167,#f2bba0,#c45565,#b92335'.split(','); // http://colorpalettes.net/color-palette-3270/
const o12 = [3, 0, 1, 2, 4];
const c13 = '#395563,#74a3b7,#adbfc3,#f2eee2,#958f77'.split(','); // http://colorpalettes.net/color-palette-28/
const o13 = [4, 0, 1, 2, 3];
const c14 = '#81aae0,#a0d6f0,#e6dbe8,#70b6ea,#e8aeae'.split(','); // http://colorpalettes.net/color-palette-37/
const o14 = [2, 4, 0, 1, 3];

const f1 = (colorsAray, order) => {
  return {
    defaultNode: colorsAray[order[0]],
    selectedNode: colorsAray[order[1]],
    influencedNode: colorsAray[order[2]],
    influencedByNode: colorsAray[order[3]],
    highlightedNode: colorsAray[order[4]],
  };
};

export const colors = {
  ...f1(c92, o9),
  // defaultNode: '#e0e8e5',
  // selectedNode: '#0b272d',
  // highlightedNode: 'violet',
  // influencedNode: '#5a9696',
  // influencedByNode: '#bbdb93',
  defaultLink: 'rgba(220, 220, 220, 0.5)',
  yearLines100: '#93a8b0',
  yearLines10: '#93a8b0',
};

/**
 * Count a radius of a circle representing a person in graph
 * @param {Number} count - amount of influenced people (if 0, becomes 1)
 * @returns {Number} - a radius of a circle
 */
export function nodeRadius({ influencedCount: count }) {
  count = count < 1 ? 1 : count;
  return Math.sqrt(count * 5);
}
