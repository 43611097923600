import React from 'react';
import styled from 'styled-components';

const PictureContainer = styled.div`
  position: relative;
  width: ${(17 / 26) * 380}px;
  height: ${(20 / 26) * 380}px;
  max-width: ${(17 / 26) * 100}vw;
  max-height: ${(20 / 26) * 100}vw;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('/pictures/loading.gif');
  overflow: hidden;
  margin: 0 auto !important;
`;

const Picture = styled.img`
  width: 100%;
  height: 100%;
  object-fit: ${({ empty }) => (empty ? 'contain' : 'cover')};

  /* removes default icon on broken image */
  /* @TODO but doesn't seem to work
  &:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    content: '';
  }
  */
`;

export default function PersonPicture({ picture }) {
  const noPicturePhoto = '/pictures/not-found.gif';
  return (
    <PictureContainer>
      {picture && (
        <Picture
          alt=""
          empty={picture === 'no photo'}
          src={picture !== 'no photo' ? picture : noPicturePhoto}
        />
      )}
    </PictureContainer>
  );
}
